<template>
  <section class="profile">
    <div class="page-header">
      <h3 class="page-title">
        Admin
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Admins</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{ fullName }}</li>
        </ol>
      </nav>
    </div>
    <div class="row">
    <div class="col-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <b-tabs v-model="activeTab" class="tab-solid tab-solid-primary">
            <b-tab>
              <template slot="title">
                <i class='mdi mdi-home-outline'></i> Summary
              </template>
              <div class="row">
                <div class="col-12">
                  <img class="img-lg rounded-circle mb-3" src="@/assets/images/faces/face1.jpg" alt="User Avatar">
                  <h3 class="card-title">{{ fullName }}</h3>
                </div>
              </div>
              <p>
                <span class="d-block"> <i class='mdi mdi-email font-weight-bold'></i> {{ admin.email}}</span>
                <span class="d-block"> <i class='mdi mdi-calendar font-weight-bold'></i>{{formatTime(admin.date_created, "DD/MM/YYYY hh:mm:ss") }}</span>
              </p>
            </b-tab>
            <b-tab>
              <template slot="title">
                <i class='mdi mdi-pencil'></i> Edit
              </template>
              <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
                <form @submit.prevent="handleSubmit(editAdmin)" class="forms-sample">
                 <div class="row">
                  <div class="col-md-6 grid-margin stretch-card">
                   <div class="card">
                    <div class="card-body">
                      <ValidationProvider name="Email" rules="required|email">
                        <b-form-group slot-scope="{ errors }" label="Email"  label-for="email">
                          <b-form-input type="text" id="email" v-model="admin.email" placeholder="Email"></b-form-input>
                          <p>{{ errors[0] }}</p>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="First Name" rules="required">
                        <b-form-group slot-scope="{ errors }" label="First Name"  label-for="firstName">
                          <b-form-input type="text" id="firstName" v-model="admin.first_name" placeholder="First Name"></b-form-input>
                            <p>{{ errors[0] }}</p>
                          </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Last Name" rules="required">
                        <b-form-group slot-scope="{ errors }" label="Last Name"  label-for="lastName">
                          <b-form-input type="text" id="lastName" v-model="admin.last_name" placeholder="Last Name"></b-form-input>
                          <p>{{ errors[0] }}</p>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="col-12 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <b-form-group class="float-right">
                        <b-button type="submit" variant="success" :disabled="invalid" class="mr-2">Save Changes</b-button>
                        <b-button v-b-modal.delete variant="danger" class="mr-2">Delete Admin</b-button>
                      </b-form-group>
                      <b-modal id="delete" title="Delete Admin" size="sm" @ok="deleteAdmin">
                        <p class="my-4">Are you sure want to delete?</p>
                      </b-modal>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            </ValidationObserver>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>

<script>

//import StarRating from 'vue-star-rating'
import { ValidationObserver } from 'vee-validate'
import helper from '../../util/helper.js'
export default {
  name: 'profile',
  beforeMount() {
    if (this.$route.path.indexOf('edit') >= 0) {
      this.activeTab = 1;
    }
  },
  data() {
    return {
      activeTab: 0,
      admin: {}
    }
  },
  computed: {
    fullName() {
      if(this.admin.first_name) {
        return `${this.admin.first_name} ${this.admin.last_name}`
      }
      return " "
    }
  },
  components: {
    ValidationObserver,
  },
  methods: {
    formatTime(time, format) {
      return helper.formatTime(time, format)
    },
    editAdmin() {
      this.$store.dispatch(
        "admin/updateAdmin", 
        {
          first_name: this.admin.first_name,
          last_name: this.admin.last_name,
          email: this.admin.email,
          id: this.admin.id
        }
      ).then((res) => {
        this.admin = res.data.data
        this.$snotify.success(res.data.status, {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true
        })
      })
      .catch(() => {
        this.$snotify.warning('Something went wrong!', {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true
        })
      })
    },
    deleteAdmin() {
      this.$store.dispatch(
        "admin/deleteAdmin", 
        this.admin
      ).then((res) => {
        this.$snotify.success(res.data.status, {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true
        })
        this.$router.back()
      })
      .catch(() => {
        this.$snotify.warning('Something went wrong!', {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true
        })
      })
    },
  },
  created() {
    this.$store.dispatch("admin/fetchAdmin", this.$route.params.id)
    .then(res => {
       this.admin = res.data.data
    })
    .catch(() => {
      this.$router.push("/admins/list")
    })
  }
}
</script>
